import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Route, Switch } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setHeightContent } from '../../appRedux/actions/Setting';
import Extracts from "./extracts/extracts";
import selectMethod from '../pagar/select-method/select-method';


const Views = ({ match }) => {
    // OBTENER VALORES DEL HEIGTH
    const { heightGlobal } = useSelector(({ settings }) => settings);

    const [maxHeight, setMaxHeight] = useState(600);
    // 
    const dispatch = useDispatch();

    useEffect(() => {
        //Margin top del header
        const marginTop = 48;
        //Alto de header
        const elHBar = document.getElementById("content-hbar");
        const heigthHBar = elHBar.clientHeight;
        //Alto de menu
        const elMenu = document.getElementById("content-menu");
        const heigthMenu = elMenu.clientHeight;
        //Altura del contenedor - altura y margin top del header y el alto del menu
        const maxHeight = heightGlobal - (marginTop + heigthHBar + heigthMenu);
        setMaxHeight(maxHeight);
        // 
        dispatch(setHeightContent(maxHeight));

    }, [heightGlobal, dispatch]);

    return (
        <div className="content-view-router" style={{ maxHeight: maxHeight, height: maxHeight }}>
            <Suspense fallback={<CircularProgress />}>
                <Switch>
                    <Route exact path={`${match.url}/pay`} component={lazy(() => import('./pay/pay'))} />
                    <Route exact path={`${match.url}/pay-colombia`} component={lazy(() => import('./pay-colombia/pay-colombia'))} />
                    <Route exact path={`${match.url}/purchases`} component={lazy(() => import('./purchases/purchases'))} />
                    <Route exact path={`${match.url}/purchase-detail`} component={lazy(() => import('./purchase-detail/purchase-detail'))} />
                    <Route exact path={`${match.url}/my-quota`} component={lazy(() => import('./my-quota/my-quota'))} />
                    <Route exact path={`${match.url}/movements`} component={lazy(() => import('./movements/movements'))} />
                    <Route exact path={`${match.url}/extracts`} component={() => <Extracts maxHeight={maxHeight} />} />
                    <Route exact path={`${match.url}/profile`} component={lazy(() => import('./profile/profile'))} />
                    <Route exact path={`${match.url}/recurrency`} component={lazy(() => import('./Recurrency/BannerRecurrency'))} />
                    <Route exact path={`${match.url}/recurrency-colombia`} component={lazy(() => import('./RecurrencyColombia/BannerRecurrency'))} />
                    <Route exact path={`${match.url}`} component={lazy(() => import('./pay/pay'))} />
                </Switch>
            </Suspense>
            <Switch>
                <Route exact path={`${match.url}/select-method`} component={selectMethod} />
            </Switch>
        </div>
    );
};

export default Views;