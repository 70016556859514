import React, {useEffect, useRef, useState} from "react";
import "./styles.scss";

//mini componentes
import RenderSantasIcon from "./RenderIcons";
// import CodeComponent this replace with popup notification from "./CodeComponent";
import PromoComponent from "./PromoComponent";
import { encryptData, getCookie } from "../../util/util";

//TEST AREA
// function getQueryParam(param) {
//   const params = new URLSearchParams(window.location.search);
//   return params.get(param);
// }

//FIN TEST AREA

// Componente de paga y gana en el portal del cliente
export const SantiPuntos =({cedula, pais, user}) => {
  //elementos interactivos
  const buttonElement = useRef();
  const containerSantas = useRef(0);
  const containerActions = useRef(0);
  //state declarations 
  const [santipuntosState, setSantipuntosState] = useState(0);

  const [buttonText, setButtonText] = useState('QUIERO JUGAR');
  const [isHidden, setIsHidden] = useState(false);
  const [codePromo, setCodePromo] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [showComponentCol, setShowComponent] = useState({
    "showPan":false,
    "showCol":false
  });
  const [promoId, setPromoId] = useState(pais == "JA" ? "992217" : "978332");
  const [loadCampaing, setLoadCampaing] = useState(false);

  // let [cedulaForTest, setCedulaForTest] = useState("");
  // let [testEnviroment, setTestEnviroment] = useState(false);

  // useEffect(() => {
  //   cedula = getQueryParam("cedula");
  // }, [])

  //Funcion para validar sí el cliente tiene el saldo al día o tiene saldo vencido
  useEffect(() => {
    async function getComponetState() {
      await fetch("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/portalComponent.json?"+Math.random())
      .then(response => response.json())
      .then(data => {
        setShowComponent({
          showCol: data.CO.showComponent,
          showPan: data.PA.showComponent
        });
      })
}
    
async function getStatusSantiPunto(documento) {
    let url = process.env.REACT_APP_PUNTOS;
    let token = getCookie('token');
    let formData = {
      cedula: encryptData(documento),
      company: pais
    }
    try {
        let response = await fetch(`${url}puntos_cliente`, { method: 'POST', body: JSON.stringify(formData), headers: { 'Authorization': `Bearer ${token}` } });

        if (response.status !== 200) {
            throw new Error('Request failed with status ' + response.status);
        }

        let data = await response.json(); 
        setSantipuntosState(data.puntos);
        setLoadCampaing(true)
        setButtonText("QUIERO JUGAR");
        if (pais === "JA" ) {
          if (data.puntos >= 5 && data.aplica == "S" ) {
            await getCode(documento, "JA");
            setIsActive(true);
            setButtonText('COMIENZA A JUGAR');
          } else {
              setIsActive(false);
          }
        } else if (pais === "JP" ) {
          if (data.aplica == "S" ) {
            getCode(documento, "JP");
            setIsActive(true);
          } else {
              
          }
        }
          
    } catch (error) {
        console.error(error)
    }
}

async function getCode(documento, pais) {
    let conmpanyIdForPagaYGana = pais;
    let codigoCampana = promoId;
    const url = `https://x820g2be4k.execute-api.us-east-1.amazonaws.com/prd/v1/easypromo/validate-access-code/${conmpanyIdForPagaYGana}?validate_points=true`;

    const headers = { 'Content-Type': 'application/json' };
    let body = {
      "external_id": documento,
      "promo_id": codigoCampana || "",
      "first_name": user.name || "",
      "last_name": user.lastname || "",
      "email": user.email || "",
      "phone": user.cellphone || "",
      "country": "CO",
      "language": "es_es"
  }
  let jsonParse = JSON.stringify(body);
    try {
        let response = await fetch(url, { method: 'POST', body: jsonParse,   headers: headers });

        if (response.status !== 200) {
            throw new Error('Request failed with status ' + response.status);
        }

        let data = await response.json();

        if (data.statusCode === 200) {
            let code = data.body.data.access_code;
            setCodePromo(code);
        }
    } catch (error) {
        console.error('Error:', error);
    }
} 
getComponetState();
getStatusSantiPunto(cedula);
}, [santipuntosState]);

  return (
    showComponentCol.showCol && pais === "JA" &&
    <>
    {isHidden && <PromoComponent code={codePromo} stateIsActive={setIsHidden} campaing={promoId} />}
    <div className={`contenedor-tarjeta mt-0`}>
      <div className="container p-0 d-flex justify-content-center">
        <div className="row rounded border-0 santipunto-container w-100 p-0 overflow-hidden position-relative">
                <div className="container-bg-navidad w-100">
                    <p className="santipunto-parrafo text-center" style={{color: '#ffffff'}}>Por cada $30.000 <br/> pagados  recibirás un <br /> sticker de la felicidad</p>
                    <div ref={containerSantas} className="container-santas d-flex justify-content-center">
                      <RenderSantasIcon santipuntos={santipuntosState} />
                    </div>
                    <div ref={containerActions} className={`container-actions ${isHidden ? 'd-none' : ''}`}>
                    </div>
                </div>
                <div className="container-information d-flex flex-column align-items-center mt-0">
                    <p className="mt-4 px-md-5 px-sm-2 px-0 text-lg">
                    Acumula <b>5 stickers</b> y podrás participar < br />
                    para <b>ganar premios increíbles</b>.
                    </p>

                    <button 
                      ref={buttonElement} 
                      disabled={!isActive}
                      className={`button fetch-santipuntos mt-0 ${isActive ? 'success' : ''}`}
                      onClick={() => {
                        setIsHidden(true);
                        setButtonText('Juego comenzado');
                      }}
                    >
                      { buttonText || 'Comienza a jugar' }
                      <img className="h-100" src="https://cdn.shopify.com/s/files/1/0516/5478/7271/files/icon-clic.svg?v=1718142233" alt="" />
                    </button>
                 

                    <p className="mb-0">Los puntos se actualizan cada 24 horas.</p> 
                    <p className="terms mt-0">Actividad válida del 15 de marzo al 20 de abril del 2025.</p>
                </div>
        </div>
    </div>
    </div>
    </>
  )
}
