import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import http from '../../https/api';
import { toast } from 'react-toastify';
import {
    PURCHASES_DATA,
    DELIVERY_QUALIFICATION,
    ASSEMBLY_QUALIFICATION,
    PRODUCT_QUALIFICATION,
    GET_PURCHASE_PRODUCTS,
    GET_WARRANTY_CASES
} from "../../constants/ActionTypes";
// 
import {
    purchasesDataSuccess,
    warrantyDataSuccess,
    showLoader,
    hideLoader,
    showSubLoader,
    hideSubLoader,
    expandedPurchaseSuccess,
    deliveryQualificationResult,
    assemblyQualificationResult,
    productQualificationResult
} from "../actions/Purchases";
import { convertKeysToLowercase, encryptData, getCookie } from "../../util/util";

const purchasesRequest = async (company, document) => {
    let urlBase = process.env.REACT_APP_INGRESO;
    let token = getCookie('token');
    let formData = {
        company: company,
        cedula: encryptData(document)
    }
    const response = await http.post(`${urlBase}users/purchases/without-califications`, formData, { headers: { 'Authorization': `Bearer ${token}` }}); //5
    return response;
}

const productsRequest = async (company, consec_orden, age_orden, per_orden) => {
    let urlBase = process.env.REACT_APP_CREDITO;
    let token = getCookie('token');
    let formData = {
        company: company,
        per: encryptData(per_orden),
        agency: encryptData(age_orden),
        op: encryptData(consec_orden)
    }
    const response = await http.post(`${urlBase}purchases`, formData, { headers: { 'Authorization': `Bearer ${token}` } });    //38

    return response;
};

const warrantyActiveCases = async (document) => {
    let urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
    let token = getCookie('token');
    let formData = {
        n_ide: encryptData(document)
    }
    const response = await http.post(`${urlBase}currentCases`, formData, {headers:{'Authorization':` Bearer ${token}`}});   //7
    return response;
}

const sfValidateWarranty = async (company, document, op, cod_prod, tipoGarantiaSeus, agr, per) => {
    let urlBase = process.env.REACT_APP_JAMAR;
    let token = getCookie("token");    
    let formData = {
        producto: encryptData(cod_prod),
        op: encryptData(op),
        identificacion: encryptData(document),
        tipoGarantiaSeus: tipoGarantiaSeus,
        periodo: per,
        agencia: encryptData(agr),
        company: company     
    }
    const response = await http.post(`${urlBase}portalcliente/jamar/warrantySfCheck`, formData, 
        { headers: { 'Authorization': `Bearer ${token}` }}
    ).then(response => response).catch(error => error);
    return response;
}

const calificationRequest = async (company, op, params) => {
    
    let urlBase = process.env.REACT_APP_JAMAR;
    let token = getCookie("token");
    let formData = {
        company: company,
        numop: encryptData(op.toString()),
        value: encryptData((params.value).toString()),
        type: encryptData(params.type),
        ref: encryptData(params.ref),
        observation: params.observation ? params.observation : ''
    };
    
    const response = await http.post(`${urlBase}califications`, formData, { headers: { 'Authorization': `Bearer ${token}` } })       //43
        .then(calification => calification).catch(error => error);
    return response;
}

/**
 * Método para obtener compras del usuario en sesión.
 * @param {*} param0 
 */
function* getPurchases({ payload }) {
    // DATOS DEL USUARIO EN SESIÓN.
    const { company, document } = payload;

    try {
        // SHOW LOANDING
        yield put(showLoader());
        // 
        const resultPcs = yield call(purchasesRequest, company, document);
        
        // ESTADOS DE LA COMPRA.
        const statusPurchase = ["GENERADA", "CONFIRMADA", "ALISTAMIENTO", "RUTA", "ENTREGADA"];
        // ESTADOS DEL ARMADO.
        const statusAssembly = ["PROGRAMADO", "RUTA", "REALIZADO"];
        const purchases = [];
        //CASOS DE GARANTIA
        const cases = yield call(warrantyActiveCases, document);        
        
        const warrantyCases = cases.data.data;
        // RESULT
        if (resultPcs.data && resultPcs.data.length > 0) {
            const { data } = resultPcs;
            const dataPcs = convertKeysToLowercase(data);
            
            // ASIGNAR PRIMERA COMPRA.
            yield put(expandedPurchaseSuccess(dataPcs[0]));
            // 
            for (const [i, purchase] of dataPcs.entries()) {
                // ESTADOS DE LA ENTREGA.
                purchase.steps = [];
                const currentStep = statusPurchase.indexOf(purchase.estado);
                const purchaseSteps = statusPurchase.map((_, index) =>
                    currentStep - index < 0 ? "" : "state-green"
                );
                purchase.steps = [...purchaseSteps];
                // ESTADOS DEL ARMADO.
                purchase.stepsAsb = [];
                const currentStep02 = statusAssembly.indexOf(purchase.estado_armado);
                const assemblySteps = statusAssembly.map((_, index) =>
                    currentStep02 - index < 0 ? "" : "state-green"
                );
                purchase.stepsAsb = [...assemblySteps];
                // ARMAR DIRECCIÓN QUE VIENE ATADA A LA COMPRA
                purchase.direccion = {
                    bar: purchase.bar_despacho,
                    c_depto: purchase.depto_despacho,
                    c_mnpo: purchase.ciu_despacho,
                    //c_pais: company === 'JA' ? '01' : '02',
                    dir: purchase.dir_despacho,
                    nom_bar: purchase.nom_bar_despacho,
                    nom_mnpo: purchase.nom_ciu_despacho,
                    nom_depto: purchase.nom_dep_despacho,
                    tipo_dir: "DP",
                    zonalog: purchase.zona_despacho,
                }
                // OBTENER PRODUCTOS DE LA COMPRA.
                purchase.products = [];
                // SOLO SE OBTENDRÁN LOS PRODUCTOS DE LA PRIMERA COMPRA QUE RETORNE EL SERVICIO.
                if (i === 0) {
                    const productsResponse = yield call(productsRequest, company, purchase.consec_orden, purchase.age_orden, purchase.per_orden);
                    if (productsResponse.data) {
                        const products = productsResponse.data;
                        //Consulta en Salesforce del estado de la garantía en cada uno de los productos
                        for (let index in products) {
                            const sfConsulta = yield call(sfValidateWarranty, company, document, products[index].consec_orden, products[index].cod_producto, products[index].tipo_garantia, products[index].age_orden, products[index].per_orden);
                            products[index].sfConsulta = sfConsulta.data;
                        }

                        purchase.products = [...products];
                    }
                }
                //Consulta si tiene caso de garantia
                if (warrantyCases) {
                    if (warrantyCases.some(e => e.numeroOp === purchase.consec_orden)) {
                        let warrantyArray = []
                        for (let index in warrantyCases) {
                            if (warrantyCases[index].numeroOp === purchase.consec_orden) {
                                warrantyArray.push(warrantyCases[index])
                            }
                        }
                        console.log("warrantyArray", warrantyArray)
                        purchase.warrantyCase = warrantyArray
                        console.log("purchase", purchase)
                    }
                }


                //OBTENER DATOS DE CASOS DE GARANTÍA
                //const cases = yield call(warrantyActiveCases, document);
                purchases.push(purchase);
            }
        }
        // DATOS DE LA COMPRAS DEL USUARIO.
        yield put(purchasesDataSuccess(purchases));
        // HIDE LOANDING
        yield put(hideLoader());

    } catch (error) {
        yield put(hideLoader());
    }
}
/**
 * MÉTODO PARA LA CALIFICACIÓN DE ENTREGAS.
 */
function* sendDeliveryQualification({ payload }) {
    // PARAMETROS REQUERIDOS
    const { company, op, params, closeModalQualification, purchases } = payload;

    try {
        // 
        yield put(deliveryQualificationResult({
            showAlert: false,
            message: null,
            loader: true
        }));
        // 
        const result = yield call(calificationRequest, company, op, params);
        

        if (result.status === 200) {
            // CERRAR MODAL.
            closeModalQualification();
            // SHOW LOANDING
            yield put(showLoader());
            // ALERT - SUCCESS.
            toast.success('Calificación se registró exitosamente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            // ACTUALIZAR CALIFICACIÓN DE LE ENTREGA DE LA COMPRA.
            purchases.map((purchase) => {
                if (purchase.consec_orden === op) {
                    // SE COLOCA EN TRUE PARA MOSTRAR LA CALIFICACIÓN.
                    purchase.has_delivery_calification = true;
                    // SE AGREGA CALIFICACIÓN.
                    purchase.califications.push({
                        ref_id: params.ref,
                        type: 'E',
                        value: params.value,
                        observation: params.observation
                    });
                }

                return purchase;
            });

            // DATOS DE LA COMPRAS DEL USUARIO.
            yield put(purchasesDataSuccess(purchases));
            // 
            yield put(deliveryQualificationResult({
                showAlert: false,
                message: null,
                loader: false
            }));
        }

        if (result.status === 400) {
            // 
            yield put(deliveryQualificationResult({
                showAlert: true,
                message: "La calificación no debe ser inferior a 1 o mayor a 5",
                loader: false
            }));
        }
        // HIDE LOANDING
        yield put(hideLoader());

    } catch (error) {
        yield put(hideLoader());
    }
}

/**
 * 
 */
function* sendAssemblyQualification({ payload }) {
    // PARAMETROS REQUERIDOS
    const { company, op, params, closeModalQualification, purchases } = payload;

    try {
        // 
        yield put(assemblyQualificationResult({
            showAlert: false,
            message: null,
            loader: true
        }));
        // 
        const result = yield call(calificationRequest, company, op, params);

        if (result.status === 200) {
            // CERRAR MODAL.
            closeModalQualification();
            // SHOW LOANDING
            yield put(showLoader());
            // ALERT - SUCCESS.
            toast.success('Calificación se registró exitosamente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            // ACTUALIZAR CALIFICACIÓN DE LA ENTRAGA DE LA COMPRA.
            purchases.map((purchase) => {
                if (purchase.consec_orden === op) {
                    // SE COLOCA EN TRUE PARA MOSTRAR LA CALIFICACIÓN.
                    purchase.has_assambly_calification = true;
                    // SE AGREGA CALIFICACIÓN.
                    purchase.califications.push({
                        ref_id: params.ref,
                        type: 'A',
                        value: params.value,
                        observation: params.observation
                    });
                }

                return purchase;
            });
            // DATOS DE LA COMPRAS DEL USUARIO.
            yield put(purchasesDataSuccess(purchases));
            // 
            yield put(assemblyQualificationResult({
                showAlert: false,
                message: null,
                loader: false
            }));
        }

        if (result.status === 400) {
            // 
            yield put(assemblyQualificationResult({
                showAlert: true,
                message: "La calificación no debe ser inferior a 1 o mayor a 5",
                loader: false
            }));
        }
        // HIDE LOANDING
        yield put(hideLoader());

    } catch (error) {
        yield put(hideLoader());
    }
}
/**
 * 
 */
function* sendProductQualification({ payload }) {
    // PARAMETROS REQUERIDOS
    const { company, op, params, closeModalQualification, purchases } = payload;
    
    try {
        // 
        yield put(productQualificationResult({
            showAlert: false,
            message: null,
            loader: true
        }));
        // 
        const result = yield call(calificationRequest, company, op, params);

        if (result.status === 200) {
            // CERRAR MODAL.
            closeModalQualification();
            // SHOW LOANDING
            yield put(showLoader());
            // ALERT - SUCCESS.
            toast.success('Calificación se registró exitosamente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            // ACTUALIZAR CALIFICACIÓN DEL PRODUCTO DE LA COMPRA.
            purchases.map((purchase) => {
                if (purchase.consec_orden === op) {
                    // 
                    purchase.products.map((product) => {
                        if (product.cod_producto === params.ref) {
                            product.calification_value = params.value;
                            product.calification_obs = params.observation;
                        }

                        return product;
                    });
                }

                return purchase;
            });
            // DATOS DE LA COMPRAS DEL USUARIO.
            yield put(purchasesDataSuccess(purchases));
            // 
            yield put(productQualificationResult({
                showAlert: false,
                message: null,
                loader: false
            }));
        }

        if (result.status === 400) {
            // 
            yield put(productQualificationResult({
                showAlert: true,
                message: "La calificación no debe ser inferior a 1 o mayor a 5",
                loader: false
            }));
        }
        // HIDE LOANDING
        yield put(hideLoader());

    } catch (error) {
        yield put(hideLoader());
    }
}
function* getWarrantyCases({ payload }) {
    const { document } = payload;
    try {
        //yield put(showSubLoader());
        const cases = yield call(warrantyActiveCases, document);
        return cases;
        yield put(warrantyDataSuccess(cases));
        // HIDE LOANDING
        yield put(hideSubLoader());
    } catch (error) {
        yield put(hideLoader());
    }
    //OBTENER DATOS DE CASOS DE GARANTÍA

}

function* getPurchaseProducts({ payload }) {
    // 
    console.log("payload", payload)
    const { userData, purchases, purchase } = payload;

    try {
        // 
        if (!purchase.products[0]) {
            // SHOW LOANDING
            yield put(showSubLoader());
            // OBTENER PRODUCTOS.
            const productsResponse = yield call(productsRequest, userData.company, purchase.consec_orden, purchase.age_orden, purchase.per_orden);

            //CASOS DE GARANTIA
            const cases = yield call(warrantyActiveCases, userData.document);
            const warrantyCases = cases.data.data;
            // VALIDAR RESPUESTA.
            if (productsResponse.data) {
                // 
                const products = productsResponse.data;
                //Consulta en Salesforce del estado de la garantía en cada uno de los productos
                for (let index in products) {
                    const sfConsulta = yield call(sfValidateWarranty, userData.company, userData.document, products[index].consec_orden, products[index].cod_producto, products[index].tipo_garantia, products[index].age_orden, products[index].per_orden)
                    products[index].sfConsulta = sfConsulta.data;
                    //Consulta si tiene caso de garantia
                    if (warrantyCases) {
                        if (warrantyCases.some(e => e.numeroOp === products[index].consec_orden)) {
                            if (warrantyCases.some(e => e.codigoProducto === products[index].cod_producto)) {
                                products[index].warrantyCase = warrantyCases.find(e => (e.numeroOp === products[index].consec_orden && e.codigoProducto === products[index].cod_producto))
                            }
                        }
                    }

                }
                // ACTUALIZAR CALIFICACIÓN DE LE ENTREGA DE LA COMPRA.
                purchases.map((data) => {
                    if (data.consec_orden === purchase.consec_orden) {
                        data.products = products;
                    }
                    return data;
                });

            }
            // DATOS DE LA COMPRAS DEL USUARIO.
            yield put(purchasesDataSuccess(purchases));
            // HIDE LOANDING
            yield put(hideSubLoader());
        }

    } catch (error) {
        yield put(hideLoader());
    }
}

export function* getPurchasesData() {
    yield takeEvery(PURCHASES_DATA, getPurchases);
}

export function* deliveryQualification() {
    yield takeEvery(DELIVERY_QUALIFICATION, sendDeliveryQualification);
}

export function* assemblyQualification() {
    yield takeEvery(ASSEMBLY_QUALIFICATION, sendAssemblyQualification);
}

export function* productQualification() {
    yield takeEvery(PRODUCT_QUALIFICATION, sendProductQualification);
}

export function* purchaseProducts() {
    yield takeEvery(GET_PURCHASE_PRODUCTS, getPurchaseProducts);
}

export function* warrantyCases() {
    yield takeEvery(GET_WARRANTY_CASES, getWarrantyCases);
}

export default function* rootSaga() {
    yield all([
        fork(getPurchasesData),
        fork(deliveryQualification),
        fork(assemblyQualification),
        fork(productQualification),
        fork(purchaseProducts),
        fork(warrantyCases)
    ]);
}