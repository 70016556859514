import React from 'react';

import santaIcon from './images/icon-santipuntos-render.svg'; 
import santaIconOpaco from './images/icon-santipuntos-render-opaco.svg'; 



const RenderSantasIcon = ({ santipuntos }) => {
  let totalPuntos = santipuntos;
  let maximosPuntos = 5;

  const santas = Array.from({ length: maximosPuntos }, (_, index) => (
    <div 
    key={index}
    className={`icon-puntos d-flex flex-column justify-content-center align-items-center align-content-center 
      ${totalPuntos > index ? 'active' : 'opacity'}`
    }>
      <img src={totalPuntos > index ? santaIcon : santaIconOpaco} alt="" />
      <div className='d-flex justify-content-center flex-column align-items-center'>
      </div>
    </div>
  ));

  return <div className='d-flex justify-content-center' key={santas}>{santas}</div>;
};

export default RenderSantasIcon;