import React, { useEffect, useState } from 'react';
import { use } from 'react';

const PromoComponent = ({code,stateIsActive, campaing}) => {
    const [step, setStep ] = useState(1);
    return (
        <>
        {
            step == 2 ? (
                <div className="container-iframe">
                    <iframe 
                        src={`https://promos.jamar.com/p/${campaing}?lt=${code}`}
                        frameBorder="0" 
                    />
                    <button className='close-promocion' onClick={() => {stateIsActive(false)}} >X</button>
                </div>
            ) : 
            <div className="container-iframe">
                <div className="indications d-flex justify-content-center align-items-center flex-column">
                    <h4>
                        ⚠️ Aviso importante
                    </h4>
                    <p>
                    Si tienes problemas para acceder a la promoción, asegúrate de habilitar las cookies en tu navegador o intenta usar otro navegador.
                    </p>

                    <button className='next-click mb-2'  onClick={() => {setStep(2)}}>Ir a la promo</button>
                    <p className="terms">Tus puntos se actualizan cada 24 horas.</p>
                </div>
                <button className='close-promocion' onClick={() => {stateIsActive(false)}} >X</button>
            </div>
        }
        </>
    );
};

export default PromoComponent;