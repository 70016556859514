import React from 'react';
import { Route, Switch } from "react-router-dom";
import consultar from './consultar/consultar';
import selectMethod from './select-method/select-method';
import selectMethodColombia from './select-method-colombia/select-method-colombia';
import confirmacionpago from './confirmacionpago';

const Pagar = ({ match }) => {
  // OBTENER VALORES DEL HEIGTH

  
  return (
              <>
               <Switch>
                  <Route exact path={`${match.url}`} component={consultar} />
                  <Route exact path={`${match.url}/colombia`} component={consultar} />
                  <Route exact path={`${match.url}/select-method`} component={selectMethod} />
                  <Route exact path={`${match.url}/metodo`} component={selectMethodColombia} />
                  <Route exact path={`${match.url}/confirmacionpago`} component={confirmacionpago} />
                  <Route exact path={`${match.url}/confirmacionpagoyappi`} component={confirmacionpago} />
                  <Route exact path={`${match.url}/confirmacionpayu`} component={confirmacionpago} />
                  <Route exact path={`${match.url}/errorpagoyappi`} component={confirmacionpago} />
              </Switch>
              </>
  );
};

export default Pagar;